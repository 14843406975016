import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
//import "../CSS/bootstrap.css";

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
      let noTawk = ["/directdebitsettings", "/eventlogs", "/pricingmodel", "/contractcommit", "/invoice", "/bucket"]
      let noNavMenu = ["/restore", "/check", "/directdebitsettings", "/eventlogs", "/orglogs", "/main", "/bucket", "/pricingmodel", "/masteradmin", "/reseller", "/ContractCommit", "invoice", "/settings","/jobmanagement"]
    let pathName = window.location.pathname;
    let navMenu = (<NavMenu />);
      let tawkWidget = null;

      if (noNavMenu.includes(pathName.toLowerCase())) {          
      navMenu = null;
    } 
      if (!noTawk.includes(pathName.toLowerCase()))
      {
       if((!window.location.hostname.includes("365demo")) && (!window.location.hostname.includes("localhost")))
          {
        tawkWidget = (
          <Helmet>
            <script type="text/javascript" src="./tawkscript.js" async={true} />
          </Helmet>
          );
      }      
    }
    return (
      <div>
        {tawkWidget}
            <Container fluid className="p-0">
        {navMenu}
        {this.props.children}
        </Container>
      </div>
    );
  }
}
