import React, { useEffect } from "react";
import "../../CSS/general.css";

const ActionButtons = (props) => {
    const handleBack = () => {
       props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
       props.lastStep();
    };

    const handleCancel = () => {
        props.closePopupModal();
    };
    

    return (
        <div>
            <table cellSpacing="5" cellPadding="5">
                <tbody>
                    <tr>
                        <td align="right">
                            {props.enableCreateRepos &&
                                <button onClick={props.CreateRepos} className="btn btn-primary btn-sm one">Create Repositories</button>
                            }

                            {props.enableCreateRepository &&
                                <button onClick={props.CreateRepository} className="btn btn-primary btn-sm two">Create Repository</button>
                            }
                        </td>
                        <td align="right">
                            {props.currentStep > 1 && (
                                <button onClick={handleBack} className= "btn btn-primary btn-sm">Previous</button>
                            )}
                        </td>
                        <td align="right">
                            {props.currentStep < props.totalSteps && (
                                <button onClick={handleNext} className="btn btn-primary btn-sm">Next</button>
                            )}
                            {props.currentStep === props.totalSteps && (
                                <button style={{ cursor: props.isDisabled ? 'not-allowed' : 'pointer', }}
                                    onClick={handleFinish} className="btn btn-primary btn-sm"
                                    disabled={props.isDisabled}
                                    aria-hidden={props.isDisabled ? 'true' : 'false'}
                                    tabIndex={props.isDisabled ? -1 : 0}>Finish</button>
                            )}
                        </td>
                        <td align="right">
                            <button onClick={handleCancel} className="btn btn-secondary btn-sm">Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default ActionButtons;