import React, { useMemo, useRef, useState, useEffect, useCallback, useContext } from "react";
import { AgGridReact } from 'ag-grid-react';
import Switch from "react-switch";
import { AppContext } from "../MainComponents/AppContext";
import Axios from "axios";
import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";
import LoaderGif from "../../images/365 loading.gif";

const PopupModal = props => {
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002"
    };
    const gridRef = useRef();
    const [appContext] = useContext(AppContext);
    const [appOrg, setAppOrg] = useContext(AppContext);

    const [columnDefs, setColumnDefs] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [gridApi, setGridApi] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [populate, setPopulate] = useState(false);

    const rowHeight = 25;
    const headerHeight = 35;
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true
    }), []);

    function setColumnDefinition() {
        switch (props.itemType) {
            case 'user':
                {
                    let columnDefs = [
                        { field: 'displayName', filter: true, headerName: 'Object', width: 350, },
                        {
                            field: 'type', filter: true, headerName: 'Type', width: 300
                        },
                        {
                            field: 'mailbox',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Mailbox" class='mail-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                });
                                return input;
                            }
                        },
                        {
                            field: 'archiveMailbox',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Archive Mailbox" class='archivemail-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                });
                                return input;
                            }
                        },
                        {
                            field: 'onedrive',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Onedrive" class='onedrive-icon'></span>`
                            },
                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;

                                });
                                return input;
                            }
                        },
                        {
                            field: 'site',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Sharepoint" class='sharepoint-icon'></span>`
                            },
                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                });
                                return input;
                            }
                        }
                    ];
                    setColumnDefs(columnDefs);
                    break;
                }
            case 'group':
                {
                    let columnDefs = [
                        { field: 'displayName', filter: true, headerName: 'Object', width: 280, },
                        {
                            field: 'type', filter: true, headerName: 'Type', width: 260
                        },
                        {
                            field: 'mailbox',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Mailbox" class='mail-icon'></span>`
                            },
                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                    params.node.data.members = params.value;
                                });
                                return input;
                            }
                        },
                        {
                            field: 'archiveMailbox',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Archive Mailbox" class='archivemail-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                    params.node.data.members = params.value;
                                });
                                return input;
                            }
                        },
                        {
                            field: 'onedrive',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Onedrive" class='onedrive-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                    params.node.data.members = params.value;
                                });
                                return input;
                            }
                        },
                        {
                            field: 'site',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Sharepoint" class='sharepoint-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                    params.node.data.members = params.value;
                                });
                                return input;
                            }
                        },
                        {
                            field: 'groupMailbox',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Group Mailbox" class='groupmail-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.disabled = params.node.data.type !== "Office365";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                    params.node.data.members = params.value;
                                });
                                return input;
                            }
                        },
                        {
                            field: 'groupSite',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Group Sharepoint" class='groupsite-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.disabled = params.node.data.type !== "Office365";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                    params.node.data.members = params.value;
                                });
                                return input;
                            }
                        }
                    ];
                    setColumnDefs(columnDefs);
                    break;
                }
            case 'site':
                {
                    let columnDefs = [
                        { field: 'name', headerName: 'Object', filter: true, width: 350, },
                        {
                            field: 'itemType', headerName: 'Type', filter: true, width: 300
                        },
                        {
                            field: 'site',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Sharepoint" class='sharepoint-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;

                                });
                                return input;
                            }
                        }
                    ];
                    setColumnDefs(columnDefs);
                    break;
                }
            case 'team':
                {
                    var columnDefs = [
                        { field: 'displayName', headerName: 'Object', filter: true, width: 350 },
                        {
                            field: 'itemType', headerName: 'Type', filter: true, width: 300
                        },
                        {
                            field: 'teams',
                            headerName: '',
                            flex: 1,
                            headerComponentParams: {
                                template: `<span title="Teams" class='teams-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.checked = params.value;
                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;
                                    params.api.redrawRows(params.node);
                                });
                                return input;
                            }
                        },
                        {
                            field: 'teamsChat',
                            headerName: '',
                            flex: 1,
                            hide: props.popupType === "ExcludeObjects",
                            headerComponentParams: {
                                template: `<span title="Teams Chat" class='teamsChat-icon'></span>`
                            },

                            cellRenderer: function (params) {
                                var input = document.createElement("input");
                                input.type = "checkbox";
                                input.disabled = !(params.node.data.teams && appOrg.isTeamsChatsOnline);
                                input.checked = params.node.data.teams && appOrg.isTeamsChatsOnline && params.value;
                                let colId = params.column.colId;
                                params.node.setDataValue(colId, input.checked);
                                params.value = !params.value;

                                input.addEventListener("click", function (event) {
                                    let checked = event.target.checked && input.checked;
                                    let colId = params.column.colId;
                                    params.node.setDataValue(colId, checked);
                                    params.value = !params.value;
                                    params.node.data.selected = params.value;

                                });
                                return input;
                            }
                        }
                    ];
                    setColumnDefs(columnDefs);
                    break;
                }
            default:
                {
                    setColumnDefs([]);
                    break;
                }
        }
    }
    const onGridReady = (params) => {
        const loadData = async () => {
            switch (props.itemType) {
                case 'user':
                    await getAppContextData(appOrg.orgDetails._links.users.href, "users", params);
                    break;
                case 'team':
                    await getAppContextData(appOrg.orgDetails._links.teams.href, "teams", params);
                    break;
                case 'group':
                    await getAppContextData(appOrg.orgDetails._links.groups.href, "groups", params);
                    break;
                case 'site':
                    await getAppContextData(appOrg.orgDetails._links.sites.href, "sites", params);
                    break;
                default:
                    break;
            }
           
        };
        loadData();
        setGridApi(params.api);

   



    }
    async function getAppContextData(link, attr, params) {

        const config = {
            headers: {
                Authorisation: `Bearer ` + appOrg.token,
            },
        };
        let payload = {
            url: link,
            orgId: appOrg.orgId,
        };

        var isloaded = false;
        setAppOrg((prev) => {
            return {
                ...prev,
                [attr]: {
                    isloaded: isloaded,
                    value: {}
                }
            }
        });
        const data = await Axios.post(API_ROUTE + Routes.GET_DATA, payload, config)
            .then((response) => {
                isloaded = true;
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                // closeAlertModal(reason.message);

            });
        switch (attr) {
            case "users":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            mailbox: false,
                            archiveMailbox: false,
                            onedrive: false,
                            site: false,
                            itemType: "user",
                            selected: false,
                            excluded: false
                        }));
                    break;
                }
            case "groups":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            mailbox: false,
                            groupMailbox: false,
                            archiveMailbox: false,
                            onedrive: false,
                            site: false,
                            groupSite: false,
                            itemType: "group",
                            selected: false,
                            excluded: false
                        }));
                    break;
                }
            case "sites":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            site: false,
                            itemType: "site",
                            selected: false,
                            excluded: false
                        }));
                    break;
                }
            case "teams":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            teams: false,
                            teamsChat: false,
                            itemType: "team",
                            selected: false,
                            excluded: false
                        }));
                    break;
                }
        }
        if (props.popupType === "AddObjects") {
            switch (props.itemType) {
                case "user": {

                    let userItems = filterExcludedObjects(data.results);

                    for (var i = 0; i < appOrg.selectedObjects.length; i++) {

                        let index = userItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                        if (userItems.find(x => x.id === appOrg.selectedObjects[i].id))
                            userItems[index] = appOrg.selectedObjects[i];
                    }
                    params.api.setRowData(userItems);
                    break;
                }
                case "group": {

                    let groupItems = filterExcludedObjects(data.results);

                    for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                        let index = groupItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                        if (groupItems.find(x => x.id === appOrg.selectedObjects[i].id))
                            groupItems[index] = appOrg.selectedObjects[i];
                    }
                    params.api.setRowData(groupItems);
                    break;
                }
                case "site": {
                    let siteItems = filterExcludedObjects(data.results);

                    for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                        let index = siteItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                        if (siteItems.find(x => x.id === appOrg.selectedObjects[i].id))
                            siteItems[index] = appOrg.selectedObjects[i];
                    }

                    params.api.setRowData(siteItems);
                    break;
                }
                case "team": {

                    let teamItems = filterExcludedObjects(data.results);

                    for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                        let index = teamItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                        if (teamItems.find(x => x.id === appOrg.selectedObjects[i].id))
                            teamItems[index] = appOrg.selectedObjects[i];
                    }
                    params.api.setRowData(teamItems);
                    break;
                }

                default:
                    params.api.setRowData([]);
            }
        }
        else if (props.popupType === "ExcludeObjects") {
            switch (props.itemType) {
                case "user": {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            mailbox: false,
                            archiveMailbox: false,
                            onedrive: false,
                            site: false,
                            itemType: "user",
                            selected: false,
                            excluded: false
                        }));
                    let userItems = filterIncludedObjects(data.results);

                    for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                        let index = userItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                        if (userItems.find(x => x.id === appOrg.excludedObjects[i].id))
                            userItems[index] = appOrg.excludedObjects[i];
                    }
                    params.api.setRowData(userItems);
                    break;
                }
                case "group": {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            mailbox: false,
                            groupMailbox: false,
                            archiveMailbox: false,
                            onedrive: false,
                            site: false,
                            groupSite: false,
                            itemType: "group",
                            selected: false,
                            excluded: false
                        }));
                    let groupItems = filterIncludedObjects(data.results);

                    for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                        let index = groupItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                        if (groupItems.find(x => x.id === appOrg.excludedObjects[i].id))
                            groupItems[index] = appOrg.excludedObjects[i];
                    }
                    params.api.setRowData(groupItems);
                    break;
                }
                case "site": {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            site: false,
                            itemType: "site",
                            selected: false,
                            excluded: false
                        }));
                    let siteItems = filterIncludedObjects(data.results);

                    for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                        let index = siteItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                        if (siteItems.find(x => x.id === appOrg.excludedObjects[i].id))
                            siteItems[index] = appOrg.excludedObjects[i];
                    }
                    params.api.setRowData(siteItems);
                    break;
                }
                case "team": {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            teams: false,
                            teamsChat: false,
                            itemType: "team",
                            selected: false,
                            excluded: false
                        }));
                    let teamItems = filterIncludedObjects(data.results);

                    for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                        let index = teamItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                        if (teamItems.find(x => x.id === appOrg.excludedObjects[i].id))
                            teamItems[index] = appOrg.excludedObjects[i];
                    }
                    params.api.setRowData(teamItems);
                    break;
                }
                default:
                    params.api.setRowData([]);
            }
        }

        setAppOrg((prev) => {
            return {
                ...prev,
                [attr]: {
                    value: data.results,
                    isloaded: isloaded
                }
            }
        });
        setPopulate(p => !p);


    }


    function populateGrid(params) {
        if (params.api) {
            if (props.popupType === "AddObjects") {
                switch (props.itemType) {
                    case "user": {

                        let userItems = filterExcludedObjects(appOrg.users.value);

                        for (var i = 0; i < appOrg.selectedObjects.length; i++) {

                            let index = userItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                            if (userItems.find(x => x.id === appOrg.selectedObjects[i].id))
                                userItems[index] = appOrg.selectedObjects[i];
                        }
                        params.api.setRowData(userItems);
                        break;
                    }
                    case "group": {

                        let groupItems = filterExcludedObjects(appOrg.groups.value);

                        for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                            let index = groupItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                            if (groupItems.find(x => x.id === appOrg.selectedObjects[i].id))
                                groupItems[index] = appOrg.selectedObjects[i];
                        }
                        params.api.setRowData(groupItems);
                        break;
                    }
                    case "site": {

                        let siteItems = filterExcludedObjects(appOrg.sites.value);

                        for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                            let index = siteItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                            if (siteItems.find(x => x.id === appOrg.selectedObjects[i].id))
                                siteItems[index] = appOrg.selectedObjects[i];
                        }
                        params.api.setRowData(siteItems);
                        break;
                    }
                    case "team": {

                        let teamItems = filterExcludedObjects(appOrg.teams.value);

                        for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                            let index = teamItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                            if (teamItems.find(x => x.id === appOrg.selectedObjects[i].id))
                                teamItems[index] = appOrg.selectedObjects[i];
                        }
                        params.api.setRowData(teamItems);
                        break;
                    }

                    default:
                        params.api.setRowData([]);
                }
            }
            else if (props.popupType === "ExcludeObjects") {
                switch (props.itemType) {
                    case "user": {
                        appOrg.users.value = appOrg.users.value.map((x) => (
                            {
                                ...x,
                                mailbox: false,
                                archiveMailbox: false,
                                onedrive: false,
                                site: false,
                                itemType: "user",
                                selected: false,
                                excluded: false
                            }));
                        let userItems = filterIncludedObjects(appOrg.users.value);

                        for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                            let index = userItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                            if (userItems.find(x => x.id === appOrg.excludedObjects[i].id))
                                userItems[index] = appOrg.excludedObjects[i];
                        }
                        params.api.setRowData(userItems);
                        break;
                    }
                    case "group": {
                        appOrg.groups.value = appOrg.groups.value.map((x) => (
                            {
                                ...x,
                                mailbox: false,
                                groupMailbox: false,
                                archiveMailbox: false,
                                onedrive: false,
                                site: false,
                                groupSite: false,
                                itemType: "group",
                                selected: false,
                                excluded: false
                            }));
                        let groupItems = filterIncludedObjects(appOrg.groups.value);

                        for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                            let index = groupItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                            if (groupItems.find(x => x.id === appOrg.excludedObjects[i].id))
                                groupItems[index] = appOrg.excludedObjects[i];
                        }
                        params.api.setRowData(groupItems);
                        break;
                    }
                    case "site": {
                        appOrg.sites.value = appOrg.sites.value.map((x) => (
                            {
                                ...x,
                                site: false,
                                itemType: "site",
                                selected: false,
                                excluded: false
                            }));
                        let siteItems = filterIncludedObjects(appOrg.sites.value);

                        for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                            let index = siteItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                            if (siteItems.find(x => x.id === appOrg.excludedObjects[i].id))
                                siteItems[index] = appOrg.excludedObjects[i];
                        }
                        params.api.setRowData(siteItems);
                        break;
                    }
                    case "team": {
                        appOrg.teams.value = appOrg.teams.value.map((x) => (
                            {
                                ...x,
                                teams: false,
                                teamsChat: false,
                                itemType: "team",
                                selected: false,
                                excluded: false
                            }));
                        let teamItems = filterIncludedObjects(appOrg.teams.value);

                        for (var i = 0; i < appOrg.excludedObjects.length; i++) {
                            let index = teamItems.findIndex(x => x.id === appOrg.excludedObjects[i].id)
                            if (teamItems.find(x => x.id === appOrg.excludedObjects[i].id))
                                teamItems[index] = appOrg.excludedObjects[i];
                        }
                        params.api.setRowData(teamItems);
                        break;
                    }
                    default:
                        params.api.setRowData([]);
                }
            }
            //setGridApi(params.api);
        }

    }


    function filterExcludedObjects(objects) {
        if (appOrg.excludedObjects.length > 0) {
            for (var i = 0; i < appOrg.excludedObjects.length; i++)
                objects = objects.filter(item => item.id != appOrg.excludedObjects[i].id);
        }
        return objects;
    }

    const filterIncludedObjects = (params) => {
        if (appOrg.selectedObjects.length > 0) {
            for (var i = 0; i < appOrg.selectedObjects.length; i++)
                params = params.filter(item => item.id != appOrg.selectedObjects[i].id);
        }
        return params;
    }

    function UpdateUser(e) {
        setSearchTerm("");
        if (props.popupType === "AddObjects") {
            var selectedRow = [];
            gridRef.current.api.forEachNode(node => {
                if (node.data.selected ||
                    node.data.mailbox ||
                    node.data.archiveMailbox ||
                    node.data.site ||
                    node.data.onedrive ||
                    node.data.groupMailbox ||
                    node.data.groupSite ||
                    node.data.teams ||
                    node.data.teamsChat) {
                    switch (node.data.itemType) {
                        case "user":
                            {
                                let index = appOrg.users.value.findIndex(item => item.id === node.data.id);
                                appOrg.users.value[index].mailbox = node.data.mailbox === undefined ? false : node.data.mailbox;
                                appOrg.users.value[index].archiveMailbox = node.data.archiveMailbox === undefined ? false : node.data.archiveMailbox;
                                appOrg.users.value[index].site = node.data.site === undefined ? false : node.data.site;
                                appOrg.users.value[index].onedrive = node.data.onedrive === undefined ? false : node.data.onedrive;
                                appOrg.users.value[index].selected = node.data.selected;
                                selectedRow.push(appOrg.users.value[index]);

                                break;
                            }
                        case "group":
                            {
                                let index = appOrg.groups.value.findIndex(item => item.id === node.data.id);
                                appOrg.groups.value[index].mailbox = node.data.mailbox === undefined ? false : node.data.mailbox;
                                appOrg.groups.value[index].archiveMailbox = node.data.archiveMailbox === undefined ? false : node.data.archiveMailbox;
                                appOrg.groups.value[index].site = node.data.site === undefined ? false : node.data.site;
                                appOrg.groups.value[index].onedrive = node.data.onedrive === undefined ? false : node.data.onedrive;
                                appOrg.groups.value[index].groupMailbox = node.data.groupMailbox === undefined ? false : node.data.groupMailbox;
                                appOrg.groups.value[index].groupSite = node.data.groupSite === undefined ? false : node.data.groupSite;
                                appOrg.groups.value[index].selected = node.data.selected;
                                selectedRow.push(appOrg.groups.value[index]);
                                break;
                            }
                        case "site":
                            {
                                let index = appOrg.sites.value.findIndex(item => item.id === node.data.id);
                                appOrg.sites.value[index].site = node.data.site === undefined ? false : node.data.site;
                                appOrg.sites.value[index].selected = node.data.selected;
                                appOrg.sites.value[index].displayName = node.data.title;
                                selectedRow.push(appOrg.sites.value[index]);
                                break;
                            }
                        case "team":
                            {
                                let index = appOrg.teams.value.findIndex(item => item.id === node.data.id);
                                appOrg.teams.value[index].teams = node.data.teams === undefined ? false : node.data.teams;
                                appOrg.teams.value[index].teamsChat = node.data.teamsChat === undefined ? false : node.data.teamsChat;
                                appOrg.teams.value[index].selected = node.data.selected;
                                appOrg.teams.value[index].name = node.data.description;
                                selectedRow.push(appOrg.teams.value[index]);
                                break;
                            }
                    }
                }
            });
            appOrg.selectedObjects = appOrg.selectedObjects.filter(function (obj) {
                return obj.itemType !== props.itemType;
            });

            appOrg.selectedObjects.push(...selectedRow);
        }
        else if (props.popupType === "ExcludeObjects") {
            var selectedRow = [];
            gridRef.current.api.forEachNode(node => {
                if (node.data.selected ||
                    node.data.mailbox ||
                    node.data.archiveMailbox ||
                    node.data.site ||
                    node.data.onedrive ||
                    node.data.groupMailbox ||
                    node.data.groupSite ||
                    node.data.teams ||
                    node.data.teamsChat) {
                    switch (node.data.itemType) {
                        case "user":
                            {
                                let index = appOrg.users.value.findIndex(item => item.id === node.data.id);
                                appOrg.users.value[index].mailbox = node.data.mailbox;
                                appOrg.users.value[index].archiveMailbox = node.data.archiveMailbox;
                                appOrg.users.value[index].site = node.data.site;
                                appOrg.users.value[index].onedrive = node.data.onedrive;
                                appOrg.users.value[index].excluded = node.data.selected;
                                appOrg.users.value[index].selected = false;
                                selectedRow.push(node.data);

                                break;
                            }
                        case "group":
                            {
                                let index = appOrg.groups.value.findIndex(item => item.id === node.data.id);
                                appOrg.groups.value[index].mailbox = node.data.mailbox;
                                appOrg.groups.value[index].archiveMailbox = node.data.archiveMailbox;
                                appOrg.groups.value[index].site = node.data.site;
                                appOrg.groups.value[index].onedrive = node.data.onedrive;
                                appOrg.groups.value[index].groupMailbox = node.data.groupMailbox;
                                appOrg.groups.value[index].groupSite = node.data.groupSite;
                                appOrg.groups.value[index].excluded = node.data.selected;
                                appOrg.groups.value[index].selected = false;
                                selectedRow.push(node.data);
                                break;
                            }
                        case "site":
                            {
                                let index = appOrg.sites.value.findIndex(item => item.id === node.data.id);
                                appOrg.sites.value[index].site = node.data.site;
                                appOrg.sites.value[index].excluded = node.data.selected;
                                appOrg.sites.value[index].displayName = node.data.title;
                                appOrg.sites.value[index].selected = false;
                                selectedRow.push(node.data);
                                break;
                            }
                        case "team":
                            {
                                let index = appOrg.teams.value.findIndex(item => item.id === node.data.id);
                                appOrg.teams.value[index].teams = node.data.teams;
                                appOrg.teams.value[index].excluded = node.data.selected;
                                appOrg.teams.value[index].name = node.data.description;
                                appOrg.teams.value[index].selected = false;
                                selectedRow.push(node.data);
                                break;
                            }
                    }
                }
            });
            appOrg.excludedObjects = appOrg.excludedObjects.filter(function (obj) {
                return obj.itemType !== props.itemType;
            });
            appOrg.excludedObjects.push(...selectedRow);
        }
        closeModal(e);
    }

    const onFilterTextChange = (e) => {
        setSearchTerm(e.target.value);
        gridApi.setQuickFilter(e.target.value);
    }

    function closeModal(e) {
        setSearchTerm("");
        setAllChecked(false);
        appOrg.rowData = [];
        e.stopPropagation();
        clearData();
        props.closeModal(true);
    }

    const selectAll = nextChecked => {
        setAllChecked(nextChecked);
        switch (props.itemType) {
            case "user": {
                let userItems = [];
                if (props.popupType === "AddObjects")
                    userItems = filterExcludedObjects(appOrg.users.value);
                else
                    userItems = filterIncludedObjects(appOrg.users.value);
                userItems = userItems.map((x) => (
                    {
                        ...x,
                        mailbox: nextChecked,
                        archiveMailbox: nextChecked,
                        onedrive: nextChecked,
                        site: nextChecked,
                        itemType: "user",
                        selected: nextChecked,
                        excluded: false
                    }));
                gridRef.current.api.setRowData(userItems);
                break;
            }
            case "group": {
                let groupItems = [];
                if (props.popupType === "AddObjects")
                    groupItems = filterExcludedObjects(appOrg.groups.value);
                else
                    groupItems = filterIncludedObjects(appOrg.groups.value);

                for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                    let index = groupItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                    if (groupItems.find(x => x.id === appOrg.selectedObjects[i].id))
                        groupItems[index] = appOrg.selectedObjects[i];
                }
                groupItems = groupItems.map((x) => (
                    {
                        ...x,
                        mailbox: nextChecked,

                        archiveMailbox: nextChecked,
                        onedrive: nextChecked,
                        site: nextChecked,
                        groupSite: x.type === "Office365" ? nextChecked : false,
                        groupMailbox: x.type === "Office365" ? nextChecked : false,
                        itemType: "group",
                        selected: nextChecked,
                        excluded: false
                    }));
                gridRef.current.api.setRowData(groupItems);
                break;
            }
            case "site": {
                let siteItems = [];
                if (props.popupType === "AddObjects")
                    siteItems = filterExcludedObjects(appOrg.sites.value);
                else
                    siteItems = filterIncludedObjects(appOrg.sites.value);

                for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                    let index = siteItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                    if (siteItems.find(x => x.id === appOrg.selectedObjects[i].id))
                        siteItems[index] = appOrg.selectedObjects[i];
                }
                siteItems = siteItems.map((x) => (
                    {
                        ...x,
                        site: nextChecked,
                        itemType: "site",
                        selected: nextChecked,
                        excluded: false
                    }));
                gridRef.current.api.setRowData(siteItems);
                break;
            }
            case "team":
                {
                    let teamItems = [];
                    if (props.popupType === "AddObjects")
                        teamItems = filterExcludedObjects(appOrg.teams.value);
                    else
                        teamItems = filterIncludedObjects(appOrg.teams.value);

                    for (var i = 0; i < appOrg.selectedObjects.length; i++) {
                        let index = teamItems.findIndex(x => x.id === appOrg.selectedObjects[i].id)
                        if (teamItems.find(x => x.id === appOrg.selectedObjects[i].id))
                            teamItems[index] = appOrg.selectedObjects[i];
                    }
                    teamItems = teamItems.map((x) => (
                        {
                            ...x,
                            teams: nextChecked,
                            teamsChat: nextChecked && appOrg.isTeamsChatsOnline && props.popupType === "AddObjects",
                            itemType: "team",
                            selected: nextChecked,
                            excluded: false
                        }));
                    gridRef.current.api.setRowData(teamItems);
                    break;
                }
        }

    }

    const clearData = () => {
        switch (props.itemType) {
            case "user":
                appOrg.users.value = appOrg.users.value.map((x) => (
                    {
                        ...x,
                        mailbox: false,
                        archiveMailbox: false,
                        onedrive: false,
                        site: false,
                        itemType: "user",
                        selected: false,
                        excluded: false
                    }));
                break;
            case "group":
                appOrg.groups.value = appOrg.groups.value.map((x) => (
                    {
                        ...x,
                        mailbox: false,
                        groupMailbox: false,
                        archiveMailbox: false,
                        onedrive: false,
                        site: false,
                        groupSite: false,
                        itemType: "group",
                        selected: false,
                        excluded: false
                    }));
                break;
            case "site":
                appOrg.sites.value = appOrg.sites.value.map((x) => (
                    {
                        ...x,
                        site: false,
                        itemType: "site",
                        selected: false,
                        excluded: false
                    }));
                break;
            case "team":
                appOrg.teams.value = appOrg.teams.value.map((x) => (
                    {
                        ...x,
                        teams: false,
                        teamsChat: false,
                        itemType: "team",
                        selected: false,
                        excluded: false
                    }));
                break;
        }
        gridRef.current.api.setRowData([]);
    };

    useEffect(() => {
    }, [props.itemType, props.popupType]);
    useEffect(() => {
        populateGrid(gridApi);
    }, [populate]);

    

    useEffect(() => {
        setColumnDefinition();
    }, [props.refresh]);

    return (
        <div>
            <div
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ width: "1000px", position: "absolute", left: "6%" }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {props.popupType == "ExcludeObjects"? "Exclude Objects" : "Add Objects"}
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body" style={{ height: "400px", minWidth: "100%", width: "inherit" }}>
                            <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: "100%", borderCollapse: "collapse" }} >
                                {/*<input type="search" placeholder="search..." value={searchTerm} onChange={onFilterTextChange} className="form-control form-control-sm" />*/}
                                {
                                    props.refresh && (
                                        <AgGridReact style={{ width: "80%" }}
                                            ref={gridRef}
                                            getRowStyle={getRowStyle}
                                            headerHeight={headerHeight}
                                            defaultColDef={defaultColDef}
                                            rowSelection="single"
                                            enableCellTextSelection="true"
                                            onGridReady={onGridReady}
                                            columnDefs={columnDefs}
                                            animateRows={true}
                                            overlayLoadingTemplate={
                                                `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                                            overlayNoRowsTemplate={
                                                '<span className="ag-overlay-loading-center">No records to show</span>'
                                            }
                                            rowHeight={rowHeight}
                                        >
                                        </AgGridReact>
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div align="left">
                                <label>
                                    <span>Select All</span>
                                </label>
                            </div>
                            <Switch onChange={selectAll} checked={allChecked} onColor={"#0069d9"} />
                            <button onClick={UpdateUser} type="submit" className="btn btn-primary btn-sm">
                                Update
                            </button>
                            <button onClick={closeModal} className="btn btn-secondary btn-sm">
                                Cancel
                            </button>
                        </ div>
                    </div>
                </div>
            </div>
            <div data-act-control-type="modalPopupBackground" id="HelpModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />
        </div>
    );
};

export default PopupModal;
