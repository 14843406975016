
let API_ROUTE_STRING;
let LEGACY_SITE_STRING;
if (window.location.href.includes("localhost")) {
    API_ROUTE_STRING = 'https://localhost:44379/api/';
    //API_ROUTE_STRING = 'https://365Api-preview.virtualdcs.co.uk:443/api/';
    //API_ROUTE_STRING = 'https://365Api-Lab.virtualdcs.co.uk:443/api/';

    LEGACY_SITE_STRING = 'http://localhost:51171/';
} else if (window.location.href.includes("365demo") || window.location.href.includes("365resellerdemo")) {
    API_ROUTE_STRING = 'https://365Api-Lab.virtualdcs.co.uk:443/api/';
    LEGACY_SITE_STRING = '/v4/';
} else if (window.location.href.includes("preview")) {
    API_ROUTE_STRING = 'https://365Api-preview.virtualdcs.co.uk:443/api/';
    LEGACY_SITE_STRING = '/v4/';
} else {
    API_ROUTE_STRING = 'https://365Api.virtualdcs.co.uk:443/api/';
    LEGACY_SITE_STRING = '/v4/';
}

export const API_ROUTE = API_ROUTE_STRING;
export const LEGACY_SITE = LEGACY_SITE_STRING;
//Routes
export const Routes = {
    // Users
    //GET_ORG: 'User/GetOrgID',
    GET_DATA: 'User/GetData/',
    GET_COPY_JOBS: 'User/GetCopyJobs',
    GET_ALL_JOBS_STATUS: 'User/GetAllJobsStatus/',
    GET_ORG: 'User/GetOrg',
    GET_REPO: 'User/GetRepos',
    GET_EVENTLOGS: 'User/GetEventlogs',
    GET_IS_ADMIN: 'User/CheckIsAdmin',
    GET_PROGRESS: 'User/GetRegistrationProgress',
    GET_MODERN_PROGRESS: 'User/GetModernRegistrationProgress',
    GET_GROUP: 'User/GetAzureGroup',
    GET_AllAZURE_GROUP: 'User/GetAllAzureGroup',
    CREATE_APPLICATION: 'User/CreateApplication',
    CREATE_APPLICATION_MFA: 'User/CreateApplicationMFA',
    CREATE_GROUP: 'User/CreateAdminGroup',
    ADD_TO_GROUP: 'User/AddUserToAdminGroup',
    CREATE_VBO_USER: 'User/CreateVBOUser',
    CREATE_VBO_USER_MFA: 'User/CreateVBOUserMFA',
    ADD_WORKER_ACCOUNTS: 'User/AddWorkerAccounts',
    ADD_WORKER_ACCOUNTS_MFA: 'User/AddWorkerAccountsMFA',
    ADD_WORKER_ACCOUNTS_PASSWORD: 'User/ManualAddWorkerAccounts',
    GET_DEVICE_CODE: 'User/GetDeviceCode',
    ADD_TO_VEEAM_DEVICE_CODE: 'User/AddToVeeamDeviceCode',
    CREATE_BUCKET: 'User/CreateBucket',
    LOGIN: 'User/Login',
    REG_CHECK: 'User/RegCheck',
    GET_TEAMSCHAT_ENABLED: 'User/GetTeamsChatEnabled',
    ROLL_BACK: 'User/RollBack',
    GET_WORKER_ACCOUNT_INFO: 'User/GetWorkerAccountInfo',
    ADD_APPLICATIONS: 'User/AddApplications',
    GET_LICENSED_USERS: 'User/GetLicensedUsers',
    GET_PROTECTION_REPORT: 'User/GetProtectionReport',
    GET_ORG_USERS: 'User/GetOrgUsers',
    VALIDATE_TOKEN: 'User/ValidateToken',
    VALIDATE_TOKEN_ADMIN: 'User/ValidateAdmin',
    GET_USER_DETAILS: 'User/GetUserDetails',
    GET_PROCESS_LOGIN: 'User/ProcessLogin',
    WRITE_LOG: 'User/WriteLog',

    //Invoice

    GET_INVOICE_LIST: 'Invoice/GetInvoiceList',
    GET_INVOICE_LINES: 'Invoice/GetInvoiceLines',
    GET_ADDITION_LINES: 'ConnectWise/GetAdditionsLines',
    GET_COMPANY_AGREEMENT_FOR_ADDITION: 'ConnectWise/GetCompanyAndAgreementForAdditionLines',
    GET_CONNECTWISE_COMPANIES: 'ConnectWise/GetAllCompanies',
    GET_CONNECTWISE_AGREEMENTS: 'ConnectWise/GetAllAgreements',
    GET_CONNECTWISE_CONTACT: 'ConnectWise/GetCompanyContact',
    GET_ALL_AGREEMENTS: 'ConnectWise/GetAllAgreements',
    CREATE_CONNECTWISE_CONTACT: 'ConnectWise/CreateCompanyContact',
    CREATE_CONNECTWISE_COMPANY: 'ConnectWise/CreateCWCompanies',
    CREATE_CONNECTWISE_AGREEMENT: 'ConnectWise/CreateAgreements',
    PROCESS_CONNECTWISE_ADDITIONLINES: 'ConnectWise/ProcessAdditionLines',
    //Bucket Dashboard

    GET_BUCKETS: 'RemovalProcess/GetBuckets',
    GET_REMOVEDORGLIST: 'RemovalProcess/GetRemovedOrgs',
    DELETE_REMOVED_ORG: 'RemovalProcess/DeleteRemovedOrgs',

    // Restore
    GET_RESTORE: 'Restore/GetRestore',
    GET_RESTORE_ITEMS: 'Restore/GetRestoreItems',
    GET_RESTORE_FOLDERS: 'Restore/GetRestoreFolders',
    EXPAND_RESTORE_FOLDER: 'Restore/ExpandRestoreFolder',
    GET_RESTORE_FOLDER_ITEMS: 'Restore/GetItems',
    GET_CHILDREN: 'Restore/GetChildren',
    GET_OBJECTS: 'Restore/GetObjects',
    GET_SUBSITES: 'Restore/GetSubsites',
    GET_RESTORE_DEVICE_CODE: 'Restore/GetDeviceCode',
    CHECK_DEVICE_CODE: 'Restore/DeviceCodeCheck',
    COMPLETE_RESTORE: 'Restore/Restore',
    SEARCH: 'Restore/Search',
    GET_ORG_NAME: 'Restore/GetOrgName',
    GET_RESTORE_POINTS: 'Restore/GetRestorePoints',
    GET_ACTIVE_SEARCH_LIST: 'Restore/GetActiveSearchList',
    IS_END_USER: 'Restore/IsEndUser',
    GET_NAVBAR_SETTINGS: 'User/GetNavbarSettings',
    GET_RESTORE_DATES: 'Restore/GetRestoreTimes',
    GET_BANK_ACCOUNT: 'GoCardless/GetBankAccount',
    GET_ORG_STATUS: 'User/GetOrgStatus',

    ADD_BANK_ACCOUNT: 'GoCardless/CreateBankAccount',
    DELETE_BANK_ACCOUNT: 'GoCardless/DeleteBankAccount',    
    TEST: 'Test/Test',
    GET_RESTORE_DOWNLOADS: 'Restore/GetRestoreDownloads',
    GET_DOWNLOAD_PRESIGNEDURL: 'Restore/GetDownloadPresignedURL',
    DELETE_S3_OBJECTS: 'Restore/DeleteS3Objects',
    LOG_RESTORESESSION: 'Restore/LogRestoreSession',
    LOG_RESTORESESSION_Comment: 'Restore/RestoreSessionComment',
    GET_RESTORESESSION_REPORT: 'Restore/RestoreSessionAudit',
    // Notifications
    GET_NOTIFICATIONS: "Notification/GetNotifications",



    // New Sign Up Routes
    GET_SET_UP_INFO: 'User/GetSetUpInfo',
    CREATE_VBOUSER: 'User/CreateVboUser',
    CREATE_BACKUP_APPLICATION: 'User/CreateApplication',
    GET_APP_PERMISSION_LINK: 'User/GetAppPermissionLink',
    CREATE_ADMIN_GROUP: 'User/CreateAdminGroup',
    ADD_TO_VEEAM: 'User/AddToVeeam',
    FORGOT_PASSWORD: 'User/ForgotPassword',

    //Settings Page
    GET_SET_SETTINGS: 'User/GetSetSettings', 
    GET_ALL_SETTINGS: 'User/GetAllSettings', 
    SET_ENCRYPTION: 'User/SetEncryption', 
    // MasterAdmin

    GET_MASTERADMIN_RESELLER_ORG: 'MasterAdmin/GetResellerOrgs',
    GET_MASTERADMIN_RESELLER_ORG_CACHE: 'MasterAdmin/GetResellerOrgsFromCache',
    GET_MASTERADMIN_RESELLER_ORG_STATUS: 'MasterAdmin/GetResellerOrgsStatus',
    GET_MASTERADMIN_RESELLERS: 'MasterAdmin/GetResellers',
    UPDATE_ORG: 'MasterAdmin/UpdateOrg',
    DELETE_ORG: 'MasterAdmin/DeleteOrg',
    CHANGE_ORG_STATUS: 'MasterAdmin/ChangeOrgStatus',
    CONVERT_ORG_TO_RESELLER: 'MasterAdmin/ConvertToReseller',
    DELETE_RESELLER: 'MasterAdmin/DeleteReseller',
    UPDATE_RESELLER: 'MasterAdmin/UpdateReseller',
    RUN_INVOICE_PROCESS: 'MasterAdmin/RunInvoiceProcess',
    RUN_INVOICE_BATCH: 'MasterAdmin/RunInvoiceBatch',
    RUN_BATCH: 'MasterAdmin/RunBatch',
    PROXY_TEST: 'MasterAdmin/ProxyTest',
    GET_JOB_LIST: 'MasterAdmin/GetJobList',

    
    // Logs
    GET_ADMIN_LOGS: 'Log/GetAdminLogs',
    GET_LOGS: 'Log/GetLogs',
    GET_SELECT_ITEMS: 'Log/GetSelectItems',
    GET_IS_DCS_ADMIN: 'User/GetIsDcsAdmin',

    //Main page calls
    SET_JOB: 'User/ManageBackupJob',
    GET_PRODUCT_FEATURE : 'User/GetProductFeature',


    // Repositories
    UPDATE_REPOSITORY: 'Repository/UpdateRepository',
    UPDATE_ENCRYPTIONKEY: 'Repository/UpdateEncyrptionKey',
    SYNC_ORG_DATA: 'User/SyncOrgData',
    
    //Entra 
    ENCRYPT_AND_SAVE: 'Entra/EncryptAndSave',
    CHECK_ENTRA_JOB: 'Entra/CheckEntraBackup',
    GET_ENTRA_BACKUP_LOGS: 'Entra/GetEntraBackupLogs',
    GET_ENTRA_JOB_HISTORY_DOWNLOAD: 'Entra/JobHistoryDownload',
    GET_ENTRA_BACKUP_SESSION_LOGS: 'Entra/GetEntraBackupSessionLogs',
    GET_ENTRA_BACKUP_DIFF_REPORT: 'Entra/GetEntraDiffReport',
    MANAGE_ENTRA_JOB:'Entra/ManageJob',
    GET_ENTRA_RESTORE_POINTS: 'Entra/GetEntraRestorePoints',
    GET_ENTRA_CHANGES: 'Entra/GetEntraChanges',
    RESTORE_ENTRA_CHANGES: 'Entra/RestoreEntraConfig',
    ADD_AZURE_ACCOUNTS: 'Entra/AddAzureBackupAccount',
    CREATE_NEW_AAD_JOB: 'Entra/CreateNewAzureJob',
    CHECK_CREATE_AAD_DIR: 'Entra/CreateAzureDirectory',
    GET_AZURE_BUCKET_LIST: 'Entra/ListAzureBucket',

    //Pricing
    PRICING_MODEL: 'TenantService/TenantServices',
    PRICING_MODEL_ID: 'TenantService/TenantServiceById',
    TENANT_USER_COUNT:'TenantService/VeeamUserCount',
    PRICING_UPDATE: 'TenantService/UpdatePriceModel',
    //Montioring
    GET_365_JOB_STATUS: 'Watch/Get365JobStatus',
    GET_365_JOB_LIVE_STATUS: 'Watch/LiveJobStatus',
    //JobManagement
    GET_365_WEEK_JOB_MANAGEMENT: 'JobManage/GetWeeklyJobStatusHistory',
    GET_365_DAY_JOB_MANAGEMENT: 'JobManage/GetDayJobStatusHistory',
}



export const Role = {
    User: 'User',
}
export const ApiVersion = {
    number : '/v7',
}

export const Urls = {
    SERVER_PATH: "https://127.0.0.1:4443/",
    HELP: "https://cloudcover365.virtualdcs.co.uk/helpme/",
}