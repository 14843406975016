import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import "../../CSS/general.css";
import { AppContext } from "../MainComponents/AppContext";
import ActionButtons from "./ActionButton";
import PopupModal from "./PopupModal";
import AuthenticationContext from "../../Store/Authentication-Context";
import Axios from "axios";
import { API_ROUTE, LEGACY_SITE, Routes, Urls } from "../../Helpers/Constants";
import LoaderGif from "../../images/365 loading.gif";

const Step3 = (props) => {
    const [helpPopup, setHelpPopup] = useState(true);
    const [gridApi, setGridApi] = useState([]);

    const [rowData, setRowData] = useState(null);
    const [companyContacts, setCompanyContacts] = useState(null);
    //Popup form
    const [infoPopup, setInfoPopup] = useState(true);
    const { setAuthContext } = useContext(AuthenticationContext);
    const validate = () => {
        props.nextStep();

    };

    function closeModal(params) {
        setHelpPopup(params);
    }

    //Ag Grid
    const rowHeight = 25;
    const headerHeight = 30;
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };
    const gridRef = useRef();

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        flex: 1
    }), []);

    const columnDefs = [
       
        { field: 'orgName', headerName: 'Organisation Name', width: 170, },
        { field: 'cwCompanyID', headerName: 'Company ID', width: 160, },
        { field: 'cwAgreementID', headerName: 'Agreement ID', width: 170, },

    ];

    const onGridReady = (params) => {
        setGridApi(params.api);
    }

    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);
    }

    //Pop Modal
    const handleClose = (params) => {
      
        setInfoPopup(params);
    };

    const openPopup = (event) => {

        const name = event.target.name;


        return setInfoPopup(false);
    }
    
   

    useEffect(() => {
      
        setRowData(props.uniqueAdditionLines.filter(line => line.cwAgreementStatus === 'Created'));
    }, [props.uniqueAdditionLines]);

    return (<div id="step2">
        <div>
            <div id="UpdatePanel1">
                <div className="card">
                    <h4 className="card-header ">
                        <span id="TitleLabel" className="SectionTitle">Created Agreements&nbsp;</span>
                  
                    </h4>
                    <div className="card-body" style={{ borderStyle: "None", height: "400px", width: "100%" }}>
                        <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", height: '100%', overflowY: "auto" }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                            onGridReady={onGridReady}
                            defaultColDef={defaultColDef}
                                domLayout="normal"
                                overlayLoadingTemplate={
                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                                overlayNoRowsTemplate={
                                    '<span className="ag-overlay-loading-center">No new Agreements have been created.</span>'
                                }
                        />
                        </div>
                    </div>
                    <div>
                        <table cellSpacing="5" cellPadding="5" align="right">
                            <tbody>
                                <tr>
                                    <td align="right">
                                        <ActionButtons {...props} nextStep={validate} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    
        <HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step3-ExcludingItems.html" />
    </div >
    );
}
export default Step3;
