import React, { Component } from "react";
import Register from "../../images/Register.png";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import { Image } from "semantic-ui-react";
import { authProvider } from "../../AuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import "../../CSS/general.css";
import { RegistrationProgressModern } from "../RegistrationProgressModern";
import Cookies from "universal-cookie";
import still from "../../images/setupimages/signup.png";
import setupbanner from "../../images/setupimages/setupbanner.png";
import authenticationError from "../../images/AuthenticationError.png";
import lock from "../../images/lock.png";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import LogRocket from "logrocket";
import { HelpDocuments } from "../HelpDocuments";

export class SignUp extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
      const tenantId = urlParams.get('tenant');
      const newApp = urlParams.get('newApp');
    var autoLogin = false;
    if(tenantId){
      autoLogin = true;
    }
    this.state = {
      progress: 0,
      inProgress: 0,
      autoLogin: autoLogin,
      getFlag: false,
      mandateCreated: false,
      responseData: null,
      showWarningText: false,
      callbackFlag: false,
      warningText: "",
      authErrorChecked: false,
        tenantId: tenantId,
        newApp: newApp ? true : false
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }
  static displayName = SignUp.name;

  login(event){
      event.preventDefault();
    authProvider.login();
  }

  logout(event) {
    event.preventDefault();
    authProvider.logout();
  }

  render() {
    var registerButton = (
      <AzureAD provider={authProvider} forceLogin={this.state.autoLogin}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              LogRocket.identify(accountInfo.account.userName, {
                name: accountInfo.account.name
              });
              return (
                <React.Fragment>
                      <div>
                          <RegistrationProgressModern tenantId={this.state.tenantId} autoLogin={this.state.autoLogin} newApp={this.state.newApp} />
                  </div>
                </React.Fragment>
              );
            case AuthenticationState.Unauthenticated:
        if (error) {
            if (error.errorCode){
                if (error.errorCode == "user_cancelled"){
                    return (
                      <React.Fragment>
                            <div className="card-deck d-flex">
                                <div className="card shadow p-2 mb-3 me-3 bg-white rounded" style={{ width: "50%" }}>
                                    <div className="card-body text-center" style={{ padding: "14%" }}>
                                        <p
                                            className="card-text mb-4 mt-4" 
                                            style={{ fontWeight: '500', fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}
                                        >
                                            Please login with your credentials below to begin the setup.
                                        </p>
                                        <Image
                                            src={microsoftSignInDarkBig}
                                            alt="Sign In"
                                            onClick={this.login}
                                            style={{ borderRadius: '0.375rem' }}
                                        />
                                        <p className="card-text mt-4"
                                            style={{ fontWeight: '500', fontSize: '15px', fontFamily: "'Poppins', sans-serif" }}>
                                            Please Note: You must be a Global Admin.
                                        </p>
                                    </div>
                                </div>
                                <div className="card shadow p-2 mb-3 bg-white rounded" style={{ width: "50%" }}>
                                    <div className="card-body text-center" style={{ padding: "0" }}>
                                        <Image
                                            style={{ width: "90%" }}
                                            src={still}
                                            alt="CloudCover365"
                                        />
                                    </div>
                                </div>
                            </div>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment>
                            <div className="card-deck d-flex">
                                <div className="card shadow p-2 mb-3 me-3 bg-white rounded" style={{ width: "50%" }}>
                                    <div className="card-body text-center" style={{ padding: "14%" }}>
                                        <img src={authenticationError} alt="Authentication Error" style={{ height: '3rem' }} />
                                        <p className="card-text" style={{ fontWeight: '500', fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}>
                                            Authentication Error
                                        </p>
                                        <p className="card-text" style={{ fontWeight: '500', fontSize: '13px', fontFamily: "'Poppins', sans-serif" }}>
                                            Please log out and try again. Should the issue presist, please contact our support team.
                                        </p>
                                        <Button onClick={this.logout} style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}>Log Out</Button>
                            </div>
                          </div>
                                <div className="card shadow p-2 mb-3 bg-white rounded" style={{ width: "50%" }}>
                            <div className="card-body text-center" style={{padding: "0"}}>
                              <Image
                                style={{ width: "90%" }}
                                src={still}
                                alt="CloudCover365"
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ); 
                  }
                } else {
                  return (
                    <React.Fragment>
                          <div className="card-deck d-flex">
                              <div className="card shadow p-2 mb-3 me-3 bg-white rounded" style={{ width: "50%" }}>
                                  <div className="card-body text-center" style={{ padding: "14%" }}>
                                      <img src={authenticationError} alt="Authentication Error" style={{ height: '3rem' }} />
                                      <p className="card-text" style={{ fontWeight: '500', fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}>
                                          Authentication Error
                                      </p>
                                      <p className="card-text" style={{ fontWeight: '500', fontSize: '13px', fontFamily: "'Poppins', sans-serif" }}>
                                          Please log out and try again. Should the issue presist, please contact our support team.
                                      </p>
                                      <Button onClick={this.logout} style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}>Log Out</Button>
                                  </div>
                              </div>
                              <div className="card shadow p-2 mb-3 bg-white rounded" style={{ width: "50%" }}>
                                  <div className="card-body text-center" style={{ padding: "0" }}>
                                      <Image
                                          style={{ width: "90%" }}
                                          src={still}
                                          alt="CloudCover365"
                                      />
                                  </div>
                              </div>
                          </div>
                    </React.Fragment>
                  ); 
                }                           
              } else {
                return (
                  <React.Fragment>
                        <div className="card-deck d-flex">
                            <div className="card shadow p-2 mb-3 me-3 bg-white rounded" style={{ width: "50%" }}>
                        <div className="card-body text-center" style={{padding: "14%"}}>
                          <p
                            className="card-text mb-4"
                                        style={{ fontWeight: '500', fontSize: '18px',fontFamily: "'Poppins', sans-serif" }}
                          >
                            Please login with your credentials below to begin the setup.
                          </p>
                          <Image
                            src={microsoftSignInDarkBig}
                            alt="Sign In"
                            onClick={this.login}
                            style={{ borderRadius: '0.375rem' }}
                          />
                          <p className="card-text mt-4"
                                        style={{ fontWeight: '500', fontSize: '15px', fontFamily: "'Poppins', sans-serif" }}>
                          Please Note: You must be a Global Admin.
                          </p>
                        </div>
                      </div>
                            <div className="card shadow p-2 mb-3 bg-white rounded" style={{ width: "50%" }}>
                        <div className="card-body text-center" style={{padding: "0"}}>
                          <Image
                            style={{ width: "90%" }}
                            src={still}
                            alt="CloudCover365"
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
            case AuthenticationState.InProgress:
              return (
                <React.Fragment>
                      <div className="card-deck d-flex">
                          <div className="card shadow p-2 mb-3 me-3 bg-white rounded" style={{ width: "50%" }}>
                              <div className="card-body text-center" style={{ padding: "14%" }}>
                                  <img src={lock} alt="lock" style={{ height:'3.5rem' }} />
                                  <p className="card-text" style={{ fontWeight: '500', fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}>
                                      Authenticating with Microsoft
                                  </p>
                                  <p className="card-text" style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                      Please complete the authentication process. You may need to enable pop ups on your browser.
                                  </p>
                      </div>
                    </div>
                          <div className="card shadow p-2 mb-3 bg-white rounded" style={{ width: "50%" }}>
                      <div className="card-body text-center" style={{padding: "0"}}>
                        <Image
                          style={{ width: "90%" }}
                          src={still}
                          alt="CloudCover365"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            default:
              return <p>Error.</p>;
          }
        }}
      </AzureAD>
    );
    const FrontPageTheme = React.lazy(() => import('../ThemeObjects/FrontPage'));
    let chosenTheme = <FrontPageTheme/>
    let themeObject = (
      <React.Suspense fallback={<></>}>
        {chosenTheme}
          </React.Suspense>);

      const currentYear = new Date().getFullYear(); 

    return (
        <div id="ContentPlaceHolder1_UpdatePanel1" style={{ maxHeight: '90vh', overflowX: 'hidden', overflowY: 'auto' }}>

            {themeObject}

            <div className="row login justify-content-center align-items-center" style={{ paddingTop: '7vh', paddingBottom:'20px' }}>

                <div className="d-flex text-center col-md-7 col-sm-12" style={{ padding: "0" }}>

                    <div className="card loginbox" style={{ borderRadius: '18px', border: '3px solid #4993dc' }}>

                        <div className="card-body" style={{ paddingRight: '9%', paddingLeft: '9%', paddingTop:'4%' }}>

                            <div style={{ display: "inline-block" }} className="mb-2">

                                <Image

                                    src={setupbanner}

                                    alt="Cloudcover 365"

                                    style={{ display: "inline-block", width: "22rem" }}

                                />

                            </div>



                            <p className="card-text" style={{ fontWeight: "500", fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}>

                                To backup Microsoft 365, we need to create links between your tenant, CloudCover 365 and our backup engine.
                                This is an automated process and you just need to sign in with your Microsoft account.

                            </p>

                            <div>{registerButton}</div>

                            <HelpDocuments message="Having trouble registering?" />

                        </div>

                        <div className="card-footer">

                            <section className="small">

                                <SubscriberAgreement />

                                |

                                <SecurityAndPrivacy />

                                <br />© Saas It {currentYear}

                            </section>

                        </div>



                        <span

                            id="ContentPlaceHolder1_lblLoginResponse"

                            style={{ color: "red" }}

                        ></span>

                    </div>

                </div>

            </div>

        </div>
    );
  }
}
