import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

const CompanyDropDown = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value || "");
    const inputRef = useRef(null); // Define a ref for the select element

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    const handleChange = (event) => {
        setValue(event.target.value);
    
    };

    // Required method to get the final value to be set in the grid
    useImperativeHandle(ref, () => ({
        getValue: () => value,
        isPopup: () => true,
        afterGuiAttached: () => {
            if (inputRef.current) {
                inputRef.current.focus(); // Focus on the dropdown after it's attached
            }
        }
    }));

    return (
        <select className='form-select' ref={inputRef} value={value} onChange={handleChange} style={{ width: '100%' }}>
            <option key={'0'} value={0} style={{ color: '#007bff', fontWeight: 'bold' }}>CreateNewCompany</option>
            {props.values && props.values.map((company) => (
                <option key={company.id} value={company.id}>
                    {company.name}
                </option>
            ))}
        </select>
    );
});

export default CompanyDropDown;
